import React, { Component } from 'react';
import './App.css';

class App extends Component {
  render() {
    return (
      <div>

    <main role="main">

      <section className="jumbotron text-center">
        <div className="container">
          <h1 className="jumbotron-heading">cogee</h1>
          <h2>Coletti Gebäudereinigung in Stuttgart und Esslingen</h2>
          <p className="lead text-justify">
            Unser Motto, Grundsatz und Slogan "make it clean!" vermittelt Kunden unsere Arbeitsweise. 
            Sauber, zuverlässig und flexibel. Nur ein paar der wichtigen Eigenschaften um ein gesundes 
            Arbeitsverhältnis zu erzielen.
          </p>
          <p className="lead text-justify">
            Wir planen und entwerfen einen passenden Reinigungsplan für Ihr Unternehmen und sorgen so 
            für ein sauberes Zusammenfinden.
          </p>
          <p className="lead text-justify">
            Betreten Sie Ihren Arbeitsplatz sauber und freundlich, sodass einem erfolgreichen Tag nichts 
            im Wege steht. Überzeugen Sie sich von unseren Dienstleistungen und kontaktieren Sie uns.          
          </p>
          <p className="lead text-justify">
            Unser freundliches Service-Personal steht Ihrem Unternehmen gerne zur Verfügung.
          </p>
          <p className="lead text-justify">
            Freundlich grüßt Sie<br />
            Ihr Coletti Gebäudereinigungs-Team
          </p>
        </div>
      </section>

    </main>

    <footer className="text-muted text-center">
      <div className="container">
        <p>Coletti Gebäudereinigung • Neuhauser Str. 31 • 73760 Ostfildern • 0711 252 960 61 • info@cogee.de</p>
        <p>Inhaber: Sandro Coletti</p>
      </div>
    </footer>
      </div>
    );
  }
}

export default App;
